import * as React from 'react'
import { SEO, Page } from '@common/components'
import {
  Header,
  Home,
  AboutUs,
  Team,
  Articles,
  Community,
  GetInTouch,
  Footer,
} from '../components'

const IndexPage = () => (
  <Page
    seoComponent={
      <SEO
        title="Home"
        keywords={['basefactor', 'web', 'lemoncode', 'frontent', 'backend']}
      />
    }
    headerComponent={<Header />}
  >
    <Home />
    <AboutUs />
    <Team />
    <Articles />
    <Community />
    <GetInTouch />
    <Footer />
  </Page>
)

export default IndexPage
